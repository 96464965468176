<template>
  <div id="miles-orders" class="pd-20">
    <a-page-header class="mb-10 pd-0">
      <div slot="title">
        <h1>Pedidos de Milhas - {{ activeOrderTab }}</h1>
      </div>

      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="excelFile.data"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer"
            src="@/assets/images/dashboard/excel.png"
            alt="img"
          />
        </downloadExcel>
      </div>
    </a-page-header>

    <a-tabs type="card" @change="changeOrdersTabs">
      <a-tab-pane key="Pendente" tab="PENDENTES"> </a-tab-pane>
      <a-tab-pane key="Concluído" tab="CONCLUÍDOS"> </a-tab-pane>
      <a-tab-pane key="Cancelado" tab="CANCELADOS"> </a-tab-pane>
    </a-tabs>

    <a-row class="haya-panels mb-20" :gutter="20">
      <a-col :span="3">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ milesOrders.pagination.total }}
          </div>
          <div class="txt">Pedidos</div>
          <div class="footer"></div>
        </div>
      </a-col>

      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">R$ {{ milesOrders.reports.totalMilesCost }}</div>
          <div class="txt">Custo</div>
          <div class="footer"></div>
        </div>
      </a-col>

      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ milesOrders.reports.totalMiles }}
          </div>
          <div class="txt">Total de Milhas</div>
          <div class="footer"></div>
        </div>
      </a-col>

      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">R$ {{ milesOrders.reports.totalValue }}</div>
          <div class="txt">Valor total</div>
          <div class="footer"></div>
        </div>
      </a-col>
    </a-row>

    <a-collapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row
          class="mt-5"
          :gutter="20"
          style="margin-left: 0; margin-right: 0"
        >
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID do Contrato</label>
              <a-input
                placeholder="Digite"
                v-model="filters.id"
                @change="getOrders()"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Faixa de Milhas</label>
              <a-input-group compact>
                <a-input
                  type="number"
                  style="width: 45%; text-align: center; border-radius: 0"
                  placeholder="Min."
                  v-model="filters.miles.min"
                  @change="getOrders()"
                />
                <a-input
                  style="
                    width: 10%;
                    border-left: 0;
                    pointer-events: none;
                    backgroundcolor: #fff;
                    border: 0;
                  "
                  placeholder="até"
                  disabled
                />
                <a-input
                  type="number"
                  style="
                    width: 45%;
                    text-align: center;
                    border-left: 0;
                    border-radius: 0;
                  "
                  placeholder="Max"
                  v-model="filters.miles.max"
                  @change="getOrders()"
                />
              </a-input-group>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Data de confirmação</label>

              <a-range-picker
                v-model="filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getOrders()"
              />
            </div>
          </a-col>

          <a-col :span="10">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                optionFilterProp="txt"
                mode="multiple"
                v-model="filters.users.selected"
                show-search
                style="width: 100%"
                @change="getOrders()"
              >
                <a-select-option
                  v-for="(item, index) of filters.users.list"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label class="filled">Programas de Milhas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="filters.milesPrograms.selected"
                show-search
                style="width: 100%"
                @change="getOrders()"
              >
                <a-select-option
                  v-for="(item, index) of filters.milesPrograms.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :pagination="false"
      :columns="[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          class: 'id',
          width: 60,
        },
        {
          title: '#',
          key: 'contract_id',
          width: 60,
          scopedSlots: { customRender: 'contract_id' },
        },
        {
          title: 'Milhas',
          dataIndex: 'miles_quantity',
          key: 'miles_quantity',
          width: 110,
          sorter: true,
        },
        {
          title: 'Custo',
          scopedSlots: { customRender: 'miles_cost' },
          key: 'miles_cost',
          width: 110,
          sorter: true,
        },
        {
          title: '/1000',
          scopedSlots: { customRender: 'per_miles' },
          key: 'per_miles',
          width: 90,
          //sorter: true,
        },

        {
          title: 'Valor Tkt.',
          scopedSlots: { customRender: 'value' },
          key: 'value',
          width: 110,
          sorter: true,
        },
        {
          title: 'Venda',
          scopedSlots: { customRender: 'sale' },
          key: 'sale',
          width: 110,
          // sorter: true,
        },
        {
          title: 'Lucro',
          scopedSlots: { customRender: 'profit' },
          key: 'profit',
          width: 115,
          class: 'available-miles profit',
          //sorter: true,
        },
        {
          title: 'Taxa Tkt.',
          scopedSlots: { customRender: 'tax_value' },
          key: 'tax_value',
          width: 110,
        },
        {
          title: 'Pgm.',
          scopedSlots: { customRender: 'miles_program' },
          width: 90,
        },
        {
          title: 'Cartão',
          key: 'miles_card',
          scopedSlots: { customRender: 'miles_card' },
          width: 80,
        },
        {
          title: 'Fornecedor',
          scopedSlots: { customRender: 'supplier' },
          width: 100,
        },
        {
          title: 'Usuário',
          dataIndex: 'user',
          key: 'user',
          scopedSlots: { customRender: 'user' },
          width: 100,
        },
        {
          title: 'Confirmação ',
          scopedSlots: { customRender: 'modified' },
          key: 'modified',
          width: '140px',
          sorter: true,
        },

        {
          title: '',
          key: 'action',
          align: 'right',
          width: '82px',
          fixed: this.$root.isAdmin() ? 'right' : 'right',
          scopedSlots: { customRender: 'action' },
        },
      ]"
      :scroll="{ x: 1300 }"
      :data-source="listOrders"
      :loading="loadingOrders"
      @change="milesCardsOrdersTableChange"
    >
      <div slot="id" slot-scope="text">{{ text }}</div>
      <div slot="contract_id" slot-scope="record">
        <a
          v-if="$root.isAdmin()"
          style="padding: 0; font-size: 11px; height: auto"
          :href="`/contracts/edit/${record.contract_id}`"
          target="_blank"
        >
          {{ record.contract_id }}
        </a>

        <div v-if="!$root.isAdmin()">
          {{ record.contract_id }}
        </div>
      </div>

      <div slot="miles_cost" slot-scope="record">
        {{ formatPricePtBr(record.raw.miles_cost) }}
      </div>

      <div slot="per_miles" slot-scope="record">R$ {{ record.per_miles }}</div>

      <div slot="profit" slot-scope="record">
        <div v-html="formatProfit(record)" />
      </div>

      <div slot="sale" slot-scope="record">R$ {{ record.sale }}</div>

      <div slot="miles_program" slot-scope="record">
        {{ record.miles_program.name }}
      </div>
      <div slot="module" slot-scope="record">
        {{ record }}
      </div>
      <div slot="value" slot-scope="record">
        R$
        {{ record.flight_value == "0,00" ? record.value : record.flight_value }}
      </div>
      <div slot="tax_value" slot-scope="record">
        {{ formatCurrency(record.tax_currency) }} {{ record.tax_value }}
      </div>
      <div slot="user" slot-scope="record">
        {{ record.first_name }} {{ record.last_name }}
      </div>
      <div slot="miles_card" slot-scope="record">
        <div v-html="formatMilesRequest(record)"></div>
      </div>
      <div slot="supplier" slot-scope="record">
        {{ record.miles_card.supplier.first_name }}
        {{ record.miles_card.supplier.last_name }}
      </div>
      <div slot="modified" slot-scope="record">
        {{
          record.status == "Pendente"
            ? "A confirmar emissão"
            : formatDateTime(record.modified)
        }}
      </div>
      <div slot="created" slot-scope="record">
        {{ formatDateTime(record) }}
      </div>
      <span class="travel-actions" slot="action" slot-scope="record">
        <span v-if="record.status == 'Pendente'">
          <a-tooltip
            v-if="!record.miles_card.id"
            placement="left"
            title="Selecionar Cartão"
          >
            <a class="view" @click="view(record)">
              <a-icon slot="prefix" type="eye-svg" />
            </a>
          </a-tooltip>
        </span>

        <span v-if="record.status == 'Pendente de Confirmação'">
          <a-tooltip placement="left" title="Aguardando Confirmação">
            <a>
              <i class="far fa-clock" style="color: #999"></i>
            </a>
          </a-tooltip>
        </span>

        <a-tooltip
          v-if="parseInt(record.id) > 166"
          placement="top"
          title="Logs"
        >
          <a class="ml-10" @click="milesOrderLogs(record.id)">
            <a-icon type="profile" style="color: #999" />
          </a>
        </a-tooltip>
      </span>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        :default-current="milesOrders.pagination.page"
        :total="milesOrders.pagination.total"
        :page-size-options="pageSizeOptions"
        show-size-changer
        @change="changeMilesOrdersPage"
        @showSizeChange="changeMilesOrdersPageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '99999'">{{ props.value }}/página</span>
          <span v-if="props.value === '99999'"> Todos</span>
        </template>
      </a-pagination>
    </div>

    <a-drawer
      placement="right"
      :closable="true"
      :visible="milesCardsModal"
      @close="milesCardsModal = false"
      width="1000px"
    >
      <template slot="title">
        <a-icon
          type="credit-card"
          style="
            top: 3px;
            margin-right: 6px;
            position: relative;
            font-size: 20px;
            font-size: 20px;
            color: #00aa21;
          "
        />
        SELECIONAR CARTÃO
        <font
          class="upper"
          v-if="theOrder.status != undefined"
          style="font-size: 11px; font-weight: 600"
        >
          <a-divider type="vertical" /> QTD. MILHAS:
          {{ theOrder.miles_quantity }}
        </font>
      </template>
      <SelectMilesCardModal
        v-if="milesCardsModal"
        :theOrder="theOrder"
        @milesCardsModal="milesCardsModal = false"
        @getOrders="getOrders()"
      />
    </a-drawer>

    <a-drawer
      :title="`PEDIDO DE MILHAS #${openedMilesOrderId}`"
      placement="bottom"
      :closable="false"
      :visible="openMilesOrderLogs"
      @close="openCloseMilesOrderLogs"
      height="400"
    >
      <a-timeline v-if="logsList.length > 0">
        <a-timeline-item v-for="(item, index) in logsList" :key="index">
          <a-tag color="#be4178" class="ml-10 mr-10"> Pedido Milhas </a-tag>
          <span v-html="item.description" />
          <a-divider type="vertical" />
          <a-icon type="clock-circle" class="mr-5 cgray" />
          <strong> {{ formatDateTime(item.created) }}</strong>
        </a-timeline-item>
      </a-timeline>
      <div v-if="logsList.length == 0">
        Nenhuma atividade foi registrada neste pedido até o momento.
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { format, parse } from "date-fns";
import SelectMilesCardModal from "@/components/orders/modal/SelectMilesCardModal.vue";
import formatThings from "@/mixins/general/formatThings.js";
import useGeneratePDF from "@/composables/generatePDF.js";

export default {
  components: { SelectMilesCardModal },
  mixins: [formatThings],
  setup() {
    const { generatePDF } = useGeneratePDF();
    return { generatePDF };
  },
  data() {
    return {
      form: this.$form.createForm(this),
      modalCreate: false,
      loadingOrders: false,
      milesCardsModal: false,
      excelFile: {
        header: `Pedidos de Milhas`,
        fileName: `Pedidos de Milhas.xls`,
        collumns: {
          ID: "id",
          "ID Contrato": "contract_id",
          Milhas: "miles_quantity",
          Custo: {
            field: "miles_cost",
            callback: (miles) => {
              return `R$ ${miles}`;
            },
          },
          "/1000": {
            field: "per_miles",
            callback: (miles) => {
              return `R$ ${this.formatPrice(miles)}`;
            },
          },
          "Valor Ticket": {
            field: "flight_value",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          Venda: {
            field: "sale",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          Lucro: {
            field: "profit",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          "Taxa Ticket": {
            field: "tax_value",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          Programa: {
            field: "miles_card",
            callback: (card) => {
              return `${card.miles_program}`;
            },
          },
          Cartão: {
            field: "miles_card",
            callback: (card) => {
              return `ID ${card.id}`;
            },
          },
          Fornecedor: {
            field: "miles_card",
            callback: (card) => {
              return `${card.supplier.first_name} ${card.supplier.last_name}`;
            },
          },
          Usuário: {
            field: "user",
            callback: (user) => {
              return `${user.first_name} ${user.last_name}`;
            },
          },
          Confirmação: "modified",
        },
        data: [],
        footer: [],
      },
      pageSizeOptions: ["10", "25", "50", "100", "200", "500", "1000", "99999"],
      milesOrders: {
        reports: {
          totalMiles: 0,
          totalValue: 0,
          totalMilesCost: 0,
        },
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
      },
      listOrders: [],
      theOrder: {},
      filters: {
        id: "",
        users: {
          list: [],
          selected: [],
        },
        period: {
          selected: [],
        },
        status: {
          list: [
            {
              label: "Concluído",
              value: "Concluído",
            },
            {
              label: "Cancelado",
              value: "Cancelado",
            },
            {
              label: "Pendente",
              value: "Pendente",
            },
            {
              label: "Pendente de Confirmação",
              value: "Pendente de Confirmação",
            },
          ],
          selected: ["Pendente", "Pendente de Confirmação"],
        },
        milesPrograms: {
          list: [],
          selected: [],
        },
        miles: {
          min: undefined,
          max: undefined,
        },
        searchTerm: "",
        order: "desc",
        orderBy: "created",
      },
      systemModules: [
        {
          label: "Cartão de milhas",
          value: "Cartão de milhas",
        },
        {
          label: "Contratos",
          value: "Contratos",
        },
        {
          label: "Fornecedores",
          value: "Fornecedores",
        },
      ],
      activeOrderTab: "Pendente",
      openMilesOrderLogs: false,
      openedMilesOrderId: undefined,
      logsList: [],
    };
  },
  beforeMount() {
    this.getOrders();
    this.getUsers();
    this.getAirlines();
    if (this.$store.state.createReport) {
      this.modalCreate = true;
    }
  },
  mounted() {
    this.$http
      .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
      .then(({ data }) => {
        let milesPrograms = [];

        data.data.forEach((airline) => {
          airline.miles_programs.forEach((program) => {
            milesPrograms.push({
              label: program.name,
              value: program.id,
            });
          });
        });

        this.filters.milesPrograms.list = milesPrograms;
      });
  },
  methods: {
    openCloseMilesOrderLogs() {
      this.openMilesOrderLogs = false;
      this.logsList = [];
      this.openedMilesOrderId = undefined;
    },
    milesOrderLogs(id) {
      this.openMilesOrderLogs = true;
      this.openedMilesOrderId = id;
      this.$http
        .get(
          `/log/list?page=1&per_page=100&module=miles-card-order&module_id=${id}`
        )
        .then(({ data }) => {
          data;
          this.logsList = data.data;
        })
        .catch(({ response }) => {
          response;
        });
    },
    formatMilesRequest(request) {
      let allCards = "";

      if (
        request.status == "Pendente" ||
        request.status == "Pedido Cancelado"
      ) {
        allCards = "";
      } else {
        allCards = `ID ${request.miles_card.id}`;
      }

      return allCards;
    },
    changeOrdersTabs(tab) {
      this.activeOrderTab = tab;
      this.getOrders();
    },
    calcTheCost(order) {
      let cost =
        (order.miles_quantity
          .replace(".", "")
          .replace(".", "")
          .replace(".", "") *
          parseFloat(order.miles_card.price_per_miles)) /
        1000;
      return this.formatPricePtBr(cost);
    },
    formatProfit(record) {
      let divClass = "";
      if (parseFloat(record.raw.profit) < 0) {
        divClass = "red";
      }

      return `<div class="${divClass}"> R$ ${record.profit} </div>`;
    },
    closeModal() {
      this.modalCreate = false;
      this.$store.state.createReport = false;
    },
    changeMilesOrdersPage(current) {
      this.milesOrders.pagination.page = current;
      this.getOrders();
    },
    changeMilesOrdersPageSize(current, pageSize) {
      this.milesOrders.pagination.page = current;
      this.milesOrders.pagination.perPage = pageSize;

      this.getOrders();
    },
    getUsers() {
      this.$http
        .get("/user/read")
        .then(({ data }) => {
          this.filters.users.list = data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    getOrders() {
      this.loadingOrders = true;

      let filters = "";

      this.activeOrderTab;

      if (this.activeOrderTab == "Pendente") {
        this.filters.status.selected = ["Pendente", "Pendente de Confirmação"];
      }

      if (this.activeOrderTab == "Cancelado") {
        this.filters.status.selected = ["Cancelado", "Pedido Cancelado"];
      }

      if (this.activeOrderTab == "Concluído") {
        this.filters.status.selected = ["Concluído"];
      }

      if (this.filters.users.selected.length > 0) {
        filters += `&user_id=${this.filters.users.selected}`;
      }

      if (this.filters.milesPrograms.selected.length > 0) {
        filters += `&miles_program_id=${this.filters.milesPrograms.selected}`;
      }

      if (this.filters.status.selected.length > 0) {
        filters += `&status=${this.filters.status.selected}`;
      }

      if (this.filters.period.selected.length > 0) {
        filters += `&period=${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`;
      }

      if (this.filters.miles.min || this.filters.miles.max) {
        let min = this.filters.miles.min;
        let max = this.filters.miles.max;

        if (min) {
          if (!max) {
            max = "5000000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&miles_filter_type=between&miles_quantity=${min}-${max}`;
      }

      if (this.filters.id) {
        filters += `&contract_id=${this.filters.id}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      this.$http
        .get(
          `/miles-card-order/list?page=${this.milesOrders.pagination.page}&per_page=${this.milesOrders.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          this.listOrders = data.data;
          this.milesOrders.pagination.total = data.meta.total;
          this.milesOrders.pagination.totalPages = data.meta.total_pages;
          this.milesOrders.reports.totalMiles = data.totalMiles;
          this.milesOrders.reports.totalValue = data.totalValue;
          this.milesOrders.reports.totalMilesCost = data.totalMilesCost;
          this.loadingOrders = false;

          this.excelFile.data = data.data;
          this.excelFile.header = `Pedidos de Milhas - ${this.activeOrderTab}`;

          this.excelFile.footer = [];
          this.excelFile.footer.push(" ");
          this.excelFile.footer.push(`TOTAL DE PEDIDOS: ${data.meta.total}`);

          this.excelFile.footer.push(`CUSTO: R$ ${data.totalMilesCost}`);

          this.excelFile.footer.push(`TOTAL DE MILHAS: ${data.totalMiles}`);

          this.excelFile.footer.push(`VALOR TOTAL: R$ ${data.totalValue}`);
        })
        .catch(({ response }) => {
          this.listOrders = response.data.data;
          this.milesOrders.pagination.total = response.data.meta.total;
          this.milesOrders.pagination.totalPages =
            response.data.meta.total_pages;
          this.milesOrders.reports.totalMiles = response.data.totalMiles;
          this.milesOrders.reports.totalValue = response.data.totalValue;
          this.milesOrders.reports.totalMilesCost =
            response.data.totalMilesCost;
          this.loadingOrders = false;
        });
    },
    showModal() {
      this.modalCreate = true;
    },
    milesCardsOrdersTableChange(pagination, filters, sorter) {
      console.log("sorter s", sorter, pagination, filters);
      this.filters.order = sorter.order != undefined ? sorter.order : "desc";
      this.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getOrders();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$store.state.createReport = false;
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        console.log(">>>", err, values);
        if (!err) {
          this.create(values);
        }
      });
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
    view(order) {
      this.milesCardsModal = true;
      this.theOrder = order;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    formatDateTime(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
};
</script>
