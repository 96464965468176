<template>
    <div>
        <a-row :gutter="20">
            <a-col :span="12">
                <label> Preço especial</label>

                <div class="travel-input">
                    <a-input
                        class="special-price"
                        v-currency
                        v-model="specialPrice"
                        placeholder="Insira o valor do preço especial"
                    />
                </div>
            </a-col>

            <a-col :span="12">
                <a-button
                    @click="updateSpecialPrice"
                    class="mt-20 green"
                    type="primary"
                    style="width: 100%"
                >
                    ATUALIZAR
                </a-button>
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
    props: {
        milesCard: Object,
    },
    data() {
        return {
            specialPrice: "",
        };
    },
    beforeMount() {
        this.specialPrice = this.milesCard.special_price;
    },
    methods: {
        updateSpecialPrice() {
            this.$http
                .post("/miles-card/update-field", {
                    id: this.milesCard.id,
                    field: "special_price",
                    value: this.specialPrice,
                    action: "special-price-milescard",
                    description: `<font class="user">${
                        this.$store.state.userData.first_name
                    } ${
                        this.$store.state.userData.last_name
                    }</font> alterou o valor do Preço Especial do cartão <font class="id">ID ${
                        this.milesCard.id
                    } ${
                        this.milesCard.miles_program
                    }</font> para <font class="val">R$ ${
                        this.specialPrice ? this.specialPrice : 0
                    }</font>.`,
                    modified_by: {
                        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                        id: this.$store.state.userData.id,
                    },
                })
                .then(({ data }) => {
                    this.$message.success(data.message);
                    this.$emit("getMilesCards", true);
                    this.$emit("specialPriceModal", false);
                    this.editCard.loading = false;
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.editCard.loading = false;
                });
        },
    },
};
</script>
