<template>
  <div>
    <div v-if="!milesCardId">
      <aRow
        class="travel-filters mb-30"
        :gutter="20"
        style="margin: 0; border-top: 0; padding-top: 0 !important"
      >
        <aCol :span="3">
          <div class="travel-input">
            <label class="filled">ID do cartão</label>
            <a-input
              placeholder=""
              v-model="milesCards.filters.id"
              @change="getMilesCards()"
            />
          </div>
        </aCol>

        <aCol :span="6">
          <div class="travel-input">
            <label class="filled">Fornecedor</label>
            <a-auto-complete
              :data-source="
                suppliers.map(({ first_name, last_name, id }) => ({
                  value: id,
                  text: `${id} - ${first_name} ${last_name}`,
                }))
              "
              v-model="milesCards.filters.supplier"
              style="width: 100%"
              placeholder="Buscar fornecedores..."
              @search="searchSupplier"
              @change="getMilesCards()"
            />
          </div>
        </aCol>
      </aRow>

      <a-table
        class="travel-table last-itens"
        :data-source="milesCards.list"
        :pagination="false"
        @change="milesCardsTableChange"
        :columns="[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            class: 'id',
            width: 80,
          },
          {
            title: 'Fornecedor',
            dataIndex: 'supplier',
            key: 'supplier',
            scopedSlots: { customRender: 'supplier' },
          },
          {
            title: 'Pgm. Milhas',
            dataIndex: 'miles_program',
            key: 'miles_program',
          },
          {
            title: 'Disponível',
            dataIndex: 'available_miles',
            key: 'available_miles',
            class: 'available-miles',
            sorter: true,
          },
          {
            title: 'Valor/1000 (R$)',
            dataIndex: 'price_per_miles',
            key: 'price_per_miles',
          },
          {
            title: 'Preço Especial (R$)',
            dataIndex: 'special_price',
            key: 'special_price',
          },
          {
            title: 'Validade',
            dataIndex: 'expires_at',
            key: 'expires_at',
            scopedSlots: {
              customRender: 'expires_at',
            },
            sorter: true,
          },
          {
            title: '',
            align: 'right',
            fixed: 'right',
            scopedSlots: {
              customRender: 'action',
            },
          },
        ]"
        :loading="milesCards.loading"
      >
        <template slot="id" slot-scope="record">
          {{ record.id }}
        </template>
        <template slot="supplier" slot-scope="record">
          <div
            style="
              max-width: 280px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ `${record.first_name} ${record.last_name}`.toUpperCase() }}
          </div>
        </template>
        <template slot="quantity" slot-scope="record">
          {{ record.id }}
        </template>
        <template slot="spent_miles" slot-scope="record">
          {{ record.spent_miles }}
        </template>
        <template slot="price_per_miles" slot-scope="record">
          R$ {{ record }}
        </template>
        <template slot="special_price" slot-scope="record">
          R$ {{ record }}
        </template>
        <template slot="selling_price" slot-scope="record">
          R$ {{ record }}
        </template>
        <template slot="expires_at" slot-scope="record">
          {{ formatDate(record) }}
        </template>
        <div slot="action" slot-scope="record">
          <a-tooltip placement="top" title="Preço Especial">
            <a class="view mr-10 cgreen" @click="openSpecialPriceModal(record)">
              <a-icon slot="prefix" style="font-size: 15px" type="dollar" />
            </a>
          </a-tooltip>

          <a-popconfirm
            ok-text="Sim"
            placement="left"
            cancel-text="Não"
            @confirm="confirmMilesEmission(record)"
          >
            <template slot="title">
              Tem certeza que deseja emitir<br />
              as milhas? Após confirmação não<br />
              será possível alterar.
            </template>
            <a-button
              type="link"
              :loading="loadingMilesEmission"
              style="padding: 0"
            >
              <i class="cgreen far fa-caret-square-right"></i>
            </a-button>
          </a-popconfirm>
        </div>
      </a-table>

      <div class="a-center mt-30" style="padding-bottom: 100px">
        <a-pagination
          :default-current="milesCards.pagination.page"
          :total="milesCards.pagination.total"
          @change="changePage"
          @showSizeChange="changePageSize"
        />
      </div>
    </div>

    <pre
      class="mt-40"
      id="live-debug"
      style="display: none; height: 300px; width: 100%; overflow: auto"
    >
            {{ theOrder }}
        </pre
    >

    <a-modal
      class="modal-special-price"
      width="430px"
      :visible="specialPriceModal"
      :footer="false"
      @cancel="specialPriceModal = false"
    >
      <template slot="title">
        <a-icon type="dollar" /> Preço Especial - ID {{ milesCard.id }}
        {{ milesCard.miles_program }}
      </template>

      <SpecialPriceModal
        v-if="specialPriceModal"
        :milesCard="milesCard"
        @getMilesCards="getMilesCards"
        @specialPriceModal="specialPriceModal = false"
      />
    </a-modal>
  </div>
</template>

<script>
import milesCardsMixin from "@/mixins/miles-cards/milesCardsMixin";
import suppliersMixins from "@/mixins/suppliersMixins";
import { format, parse } from "date-fns";
import SpecialPriceModal from "../../miles/modal/SpecialPriceModal.vue";

export default {
  components: { SpecialPriceModal },
  props: {
    theOrder: Object,
  },
  mixins: [milesCardsMixin, suppliersMixins],
  data() {
    return {
      activeProgramTab: 0,
      activeTab: "available",
      specialPriceModal: false,
      milesCard: {},
      milesCardId: false,
      loadingMilesEmission: false,
    };
  },
  beforeMount() {
    this.theOrder.milescard_id = false;
    this.milesCards.filters.milesPrograms.selected =
      this.theOrder.miles_program.name;
    this.milesCards.filters.available_miles = this.theOrder.miles_quantity
      .replace(".", "")
      .replace(".", "")
      .replace(".", "");

    this.milesCards.filters.order = "ascend";
    this.milesCards.filters.orderBy = "available_miles";

    this.getMilesCards();
  },
  methods: {
    confirmMilesEmission(milesCard) {
      console.log("milesCard", milesCard, milesCard.raw.supplier_id);
      milesCard.special_price = milesCard.special_price
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
      milesCard.price_per_miles =
        milesCard.price_per_miles
          .replace(".", "")
          .replace(".", "")
          .replace(".", "")
          .replace(",", ".") / 1000;
      milesCard.selling_price = milesCard.selling_price
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
      milesCard.value = milesCard.value
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");

      console.log(
        this.theOrder.per_miles,
        milesCard.special_price,
        milesCard.price_per_miles
      );

      this.theOrder.per_miles = ![0.0, "0,00", 0].includes(
        milesCard.special_price
      )
        ? milesCard.special_price
        : milesCard.price_per_miles;

      this.theOrder.status = "Pendente de Confirmação";
      this.theOrder.airline_id = this.theOrder.airline.id;
      this.theOrder.milescard_id = milesCard.id;
      this.theOrder.milescard_order_id = this.theOrder.id;

      this.theOrder.milescard = milesCard;
      this.theOrder.miles_quantity = this.theOrder.miles_quantity
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
      this.theOrder.value = this.theOrder.value
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
      this.theOrder.tax_value = this.theOrder.tax_value
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
      this.theOrder.flight_value = this.theOrder.flight_value
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");

      this.theOrder.supplier_id = milesCard.raw.supplier_id;
      this.theOrder.user_id = this.theOrder.user.id;
      this.theOrder.action = "emit-miles";

      this.theOrder.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.loadingMilesEmission = true;

      this.$http
        .post("/miles-card-order/update", this.theOrder)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("getOrders", true);
          this.$emit("milesCardsModal", false);
          this.loadingMilesEmission = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingMilesEmission = false;
        });
    },
    openSpecialPriceModal(milesCard) {
      this.milesCard = milesCard;
      this.specialPrice = milesCard.special_price;
      this.specialPriceModal = true;
    },
    allCardsIsSelected() {
      let flag = true;
      if (this.theOrder.milescard_ids != undefined) {
        JSON.parse(this.theOrder.milescard_ids).forEach((request) => {
          console.log(request.milescard_id);
          if (!request.milescard_id) {
            flag = false;
          }
        });
      }
      return flag;
    },
    selectMilesCard(milesCard) {
      this.theOrder.milescard_id = milesCard.id;
      this.milesCardId = milesCard.id;
    },
    removeMilesCard() {
      this.theOrder.milescard_id = "";
      this.milesCardId = "";
    },
    parseMilesOrder(milescard_ids) {
      let arr = [];
      if (milescard_ids != undefined) {
        arr = JSON.parse(milescard_ids);
      }
      return arr;
    },
    cardIsSelected() {
      let flag = false;

      if (this.theOrder.milescard_id != undefined) {
        if (this.theOrder.milescard_id) {
          flag = true;
        }
      }

      return flag;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    formatDateTime(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
};
</script>

<style lang="sass" scoped>
.card-selected
    border: 1px dashed #ddd
    border-radius: 6px
    padding: 5px 20px
    margin: 10px 0 0 0
    color: #00aa21
    font-weight: 500
    button
        font-weight: 500
        color: red
    .txt
        color: #333
        font-weight: 400
</style>
